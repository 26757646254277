import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class BillFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    amountFrom: [],
    amountTo: [],
    dateFrom: [],
    dateTo: [],
    memo: [],
    openBalanceFrom: [],
    openBalanceTo: [],
    refNo: [],
    vendor: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.amountFrom) filters.amountFrom = Number(values.amountFrom);
      if (values.amountTo) filters.amountTo = Number(values.amountTo);
      if (values.dateFrom) filters.dateFrom = values.dateForm;
      if (values.dateTo) filters.dateTo = values.dateTo;
      if (values.memo) filters.memo = values.memo;
      if (values.openBalanceFrom)
        filters.openBalanceFrom = Number(values.openBalanceFrom);
      if (values.openBalanceTo)
        filters.openBalanceTo = Number(values.openBalanceTo);
      if (values.vendor) filters.vendorName = values.vendor;
      if (values.refNo) filters.referenceNumber = values.refNo;

      return filters;
    })
  );

  amountFrom$ = this.form.get("amountFrom")?.valueChanges;
  amountTo$ = this.form.get("amountTo")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  memo$ = this.form.get("memo")?.valueChanges;
  openBalanceFrom$ = this.form.get("openBalanceFrom")?.valueChanges;
  openBalanceTo$ = this.form.get("openBalanceTo")?.valueChanges;
  refNo$ = this.form.get("refNo")?.valueChanges;
  vendor$ = this.form.get("vendor")?.valueChanges;

  reset() {
    this.form.patchValue({
      amountFrom: null,
      amountTo: null,
      dateFrom: null,
      dateTo: null,
      memo: null,
      openBalanceFrom: null,
      openBalanceTo: null,
      refNo: null,
      vendor: null,
    });
  }
}
